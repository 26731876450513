/* Container */
.requested-books-container {
    padding: 20px;
    color: #333;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
}

/* Heading */
.requested-books-container h1 {
    font-size: 2em;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* Table styling */
.requests-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
}

.requests-table th, .requests-table td {
    text-align: left;
    padding: 10px;
    border: 1px solid #ccc;
    color: #333;
}

.requests-table th {
    background-color: #f0f0f0;
    font-weight: bold;
}

.requests-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.requests-table tr:nth-child(odd) {
    background-color: #ffffff;
}

.requests-table tr:hover {
    background-color: #e8f4ff;
}

/* Status select dropdown */
.status-select {
    padding: 5px;
    border-radius: 4px;
    background-color: #ffffff;
    color: #333;
    border: 1px solid #ccc;
    margin-right: 10px;
    cursor: pointer;
}

/* Update Status button */
.update-status-btn {
    padding: 5px 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.update-status-btn:hover {
    background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .requested-books-container h1 {
        font-size: 1.5em;
    }

    .requests-table th, .requests-table td {
        padding: 8px;
    }
}
