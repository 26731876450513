.heading-h2 {
    font-size: 1.8em;
    color: #333;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.toggle-btn {
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    margin-top: 100px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: white;
}

.toggle-btn.enabled {
    background-color: #007BFF; /* Blue for enabled */
}

.toggle-btn.disabled {
    background-color: #ff4d4d; /* Red for disabled */
}

.toggle-btn:disabled {
    cursor: not-allowed;
    background-color: #ccc; /* Grey when loading */
}

.setting-card {
    background: #fff;
    border-radius: 8px;
    /* Enhance the shadow for a 3D effect */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 20px;
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
    box-sizing: border-box;
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for hover effect */
}

/* Scale shadow on hover for interaction feedback */
.setting-card:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
}

/* Style titles in cards */
.setting-card .title {
    margin-bottom: 15px;
    color: #333; /* Prefer a darker color for professional look */
    font-size: 1.25rem; /* Slightly larger title */
}