.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #F5F5F5;
}

.login-card {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
    
}

.login-image-container {
    flex-basis: 50%; /* Adjust as needed */
    max-width: 50%;
}

.login-image {
    width: 100%;
    height: auto;
    border-radius: 10px 0 0 10px; /* Rounded corners on the left side */
}

.login-form {
    flex-basis: 50%; /* Adjust as needed */
    padding: 40px;
    max-width: 50%;
    background-color: #ffffff;
}

.login-form h1 {
    text-align: center;
    margin-bottom: 24px;
    color: #037496;
    font-family: Arial, sans-serif;
}

.login-form input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Makes sure padding doesn't affect overall width */
}

.login-form button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #037496;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
}

.login-form button:hover {
    background-color: #025C78;/* Darker shade for hover effect */
}

.signup-redirect {
    text-align: center;
    margin-top: 16px;
    font-size: 14px;
}

.signup-redirect a {
    color: #007bff;
    text-decoration: none;
}

.signup-redirect a:hover {
    text-decoration: underline;
}
.signup-message {
    text-align: center;
    color: #333333; /* Dark Grey */
}

.signup-message a {
    color: #008080; /* Teal, secondary color */
    text-decoration: none;
}

.signup-message a:hover {
    text-decoration: underline;
}


@media screen and (max-width: 768px) {
    .login-card {
        flex-direction: column;
    }

    .login-image-container {
        display: none;
    }

    .login-form {
        width: 100%;
        max-width: none;
        border-radius: 10px;
    }
}




/*

    Loader

*/

.signup-action-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px; /* Adjusts the space between the button and the loader */
}

.signup-btn {
    width: auto; /* Adjust according to your layout needs */
    flex-grow: 1; /* Ensures button uses available space */
}


.loader {
    color: #ffffff;
    font-size: 5px;
    text-indent: -99999em;
    margin: 0; /* Adjust for alignment */
    position: relative;
    width: 5px; /* Scaled up for visibility */
    height: 5px; /* Scaled up for visibility */
    box-shadow: inset 0 0 0 1px;
    transform: translateZ(0);
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes load6 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #003366, 1.8em -1.8em 0 0em rgba(0, 51, 102, 0.2), 2.5em 0em 0 0em rgba(0, 51, 102, 0.2), 1.75em 1.75em 0 0em rgba(0, 51, 102, 0.2), 0em 2.5em 0 0em rgba(0, 51, 102, 0.2), -1.8em 1.8em 0 0em rgba(0, 51, 102, 0.2), -2.6em 0em 0 0em rgba(0, 51, 102, 0.5), -1.8em -1.8em 0 0em #003366;
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em #003366, 1.8em -1.8em 0 0em #003366, 2.5em 0em 0 0em rgba(0, 51, 102, 0.2), 1.75em 1.75em 0 0em rgba(0, 51, 102, 0.2), 0em 2.5em 0 0em rgba(0, 51, 102, 0.2), -1.8em 1.8em 0 0em rgba(0, 51, 102, 0.2), -2.6em 0em 0 0em rgba(0, 51, 102, 0.2), -1.8em -1.8em 0 0em rgba(0, 51, 102, 0.5);
  }
}

@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
